

import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import MyParticipateStoreType, {UpDataType} from "@/views/Group/MyParticipate/Store/indexType";

@Module({
    name:"MyParticipate",
    store,
    dynamic: true,
})
export default class MyParticipate extends VuexModule implements MyParticipateStoreType{

    MyParticipateList: any[] = [];
    get getMyParticipateList(){ return this.MyParticipateList }
    @Mutation
    SetMyParticipateList(data: any[]): void {
        this.MyParticipateList = data
    }

    MyParticipateUpData: UpDataType = {
        pageNo: 1, pageSize: 15
    };
    get getMyParticipateUpData(){ return this.MyParticipateUpData }
    @Mutation
    SetMyParticipateUpData(data: UpDataType): void {
        this.MyParticipateUpData = data
    }

}

export const MyParticipateStore = getModule( MyParticipate )
