import Storage from "@/util/Storage";
import {MyGroup, MyJoin} from "@/Api/Group";
import {MyParticipateStore} from "@/views/Group/MyParticipate/Store";

// 我发布列表
export function MyGroupList() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( MyGroup({ userId,token },MyParticipateStore.getMyParticipateUpData).then(res=>{
        return res.data
    }) )
}

// 我参加
export function MyJoinList() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( MyJoin({ userId,token },MyParticipateStore.getMyParticipateUpData).then(res=>{
        return res.data
    }) )
}
